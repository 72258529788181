.App {
  text-align: center;
  max-width: 800px;
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &__headline, &__icons {
    margin-bottom: 30px;
  }
  &__icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    &__info {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    &__links {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
